import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import { useCart } from 'react-use-cart';
import { PatternFormat } from 'react-number-format'
import { Slider } from 'antd';
import { Trash2 } from 'lucide-react';
import './styles.css'
import 'swiper/swiper-bundle.css';

import ukr_data from '../NewMenu/ukr_data.json'
import eng_data from '../NewMenu/eng_data.json'

function Order() {
  const navigate = useNavigate()
  const location = useLocation()

  const isUkr = location?.state?.lang === 'ukr'

  const swiper = useRef(null);

  const [content, setContent] = useState(ukr_data?.checkout)
  const [tabs, setTabs] = useState([
    { title: ukr_data?.checkout?.["tab:self"], icon: 'selfservice' },
    { title: ukr_data?.checkout?.["tab:delivery"], icon: 'taxi' },
  ])
  const [loading, setLoading] = useState(false)
  const [activeTab, setActiveTab] = useState(1)
  const [focusedState, setFocusedState] = useState(false)
  const [addressList, setAddressList] = useState(null)
  const [done, setDone] = useState(false)
  const [selectedMarks, setSelectedMarks] = useState([])
  const [values, setValues] = useState({
    firstName: "",
    phone: "",
    address: "",
    addressNumber: "",
    comment: ""
  })
  const [errors, setErrors] = useState({
    firstName: "",
    phone: "",
    address: "",
    addressNumber: "",
    comment: ""
  })

  const { items, emptyCart, removeItem } = useCart()

  const totalCartPrice = useMemo(() => {
    const totals = items?.map(item => item.quantity * item.pricePerItem)
    const totalPrice = totals.reduce((acc, curr) => curr + acc, 0)

    return totalPrice
  }, [items])

  useEffect(() => {
    if (!isUkr) {
      setContent(eng_data?.checkout)
      setTabs([
        { title: eng_data?.checkout?.["tab:self"], icon: 'selfservice' },
        { title: eng_data?.checkout?.["tab:delivery"], icon: 'taxi' },
      ])
    }
  }, [isUkr])

  useEffect(() => {
    const newSelectedMarks = items?.map((item, index) => {
      if (!item?.marks) {
        return {}
      }
      const arrayMarks = Object.entries(item?.marks)
      const hasOnlyOneItem = arrayMarks?.length === 1
      const marksLength = Math.floor(arrayMarks?.length / 2)

      return ({
        key: index, value: !hasOnlyOneItem ? marksLength : 3
      })
    })

    setSelectedMarks(newSelectedMarks)
  }, [items])

  const handleValue = (e) => {
    const field = e.target.name
    const value = e.target.value

    setValues(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const setError = (field, value) => {
    setErrors(prev => ({
      ...prev,
      [field]: value
    }))
  }

  const handleActiveTab = (index) => {
    setActiveTab(index)
    if (swiper) {
      swiper.current.slideTo(index)
    }
  }

  const makePlacesApiRequest = (value) => {
    const defaultBounds = {
      north: 49.283086,
      south: 49.199751,
      east: 28.580299,
      west: 28.363720,
    }

    const options = {
      componentRestrictions: { country: "ukr" },
      strictBounds: true,
      types: ["geocode"],
      bounds: defaultBounds,
    };

    const service = new window.google.maps.places.AutocompleteService();

    service.getQueryPredictions({ input: value, ...options }, (predictions) => {
      const filtered = predictions?.map(item => {
        const value = item?.structured_formatting?.main_text
        const hasNumber = /\d/.test(value)

        return hasNumber ? item : ({
          ...item,
          structured_formatting: {
            ...item?.structured_formatting,
            main_text: item?.structured_formatting?.main_text + ', 1',
          }
        })
      })

      const prepared = filtered?.map(item => item?.structured_formatting?.main_text)

      const uniqueTexts = [...new Set(prepared)]
      const uniqueObjects = uniqueTexts?.map(item => filtered.find(elem => elem?.structured_formatting?.main_text === item))

      setAddressList(uniqueObjects)
    });
  }

  const handleAddressInput = async (e) => {
    const value = e.target.value

    try {
      makePlacesApiRequest(value)
    } catch (error) {
      console.log(error.message)
    }
  }

  const handleOutsideClick = (e) => {
    if (focusedState) {
      let value = (
        document.getElementById(e.target.id)?.value ||
        document.getElementById(e.target.id)?.innerText ||
        document.getElementById('address')?.value
      )

      const ids = [
        'address',
        'checkout__content_fields_inputcontainer_dropdown',
        'checkout__content_fields_inputcontainer_dropdown_item'
      ]

      if (!ids.includes(e.target.id)) {
        setFocusedState(false)
      }

      if (!addressList?.some(item => item?.structured_formatting?.main_text === value)) {
        if (activeTab === 1) {
          setError('address', true)
        }
      } else {
        setError('address', false)
      }
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!values?.phone || values?.phone?.length < 10) {
      setError('phone', true)
      return;
    }

    if (errors?.phone || errors?.firstName || (activeTab === 1 ? errors?.address : false)) {
      return;
    }

    const flatUkrItems = ukr_data?.list?.map(item => item?.list).flat()

    const payloadItems = items
      ?.map((item, index) => {
        const matchMark = selectedMarks?.find(mark => mark?.key === index)
        return ({ ...item, mark: matchMark?.value })
      })
      ?.map((item) => {
        const match = flatUkrItems?.find(elem => elem?.image_key === item?.image_key)

        return ({
          ...item,
          title: match?.title,
          spec: match?.price?.find(el => el?.id === item?.id)?.title
        })
      })

    try {
      const payload = {
        ...values,
        items: payloadItems,
        type: activeTab === 0 ? 'self' : 'delivery',
        total: totalCartPrice,
        time: new Date().toISOString()
      }

      const apiURL = process.env.NODE_ENV === 'development' ? 'http://10.15.16.126:4202/v1/order/save' : 'https://hochubo-api.herokuapp.com/v1/order/save'

      setLoading(true)

      await fetch(apiURL, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          "Content-Type": "application/json",
        },
      })

      setDone(true)

      setLoading(false)

      setTimeout(() => {
        setDone(false);

        emptyCart();

        navigate('/')
      }, 3000)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (swiper?.current) {
      swiper.current.on('slideChange', function ({ activeIndex }) {
        setActiveTab(activeIndex)
      });
    }
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const formatter = (value) => {
    if (value === 0) {
      return 'Не гостра'
    }

    if (value === 1) {
      return 'Помірно гостра'
    }

    if (value === 2) {
      return 'Середньо гостра'
    }

    if (value === 3) {
      return 'Гостра'
    }
  }

  const handleMarkValue = (index) => (value) => {
    setSelectedMarks(prev => {
      const hasThisValue = prev?.find(item => item?.key === index)

      if (hasThisValue) {
        return (prev?.map(item => item?.key === index ? ({ ...item, value }) : item))
      } else {
        return ([...prev, { key: index, value }])
      }
    })
  }

  useEffect(() => {
    if (!totalCartPrice) {
      if (window.history.state && window.history.state.idx > 0) {
        navigate(-1);
      } else {
        navigate('/', { replace: true }); // the current entry in the history stack will be replaced with the new one with { replace: true }
      }
    }
  }, [navigate, totalCartPrice])

  if (done) {
    return (
      <div className='order centered'>
        <div className='checkout__done' />

        <div className='checkout__content_title'>
          {content?.confirm_received}
        </div>
      </div>
    )
  }

  return (
    <div
      className='order'
      onClick={handleOutsideClick}
    >
      <div className='page-head'>
        <div className='page-nav' onClick={() => navigate('/', { state: null })}>
          <div className='page-icon back' />
          <div className='page-title'>
            {isUkr ? 'Назад' : 'Back'}
          </div>
        </div>

        <div className='page-icon' />
      </div>

      <div className='checkout__content'>
        <div className='checkout__content_title'>
          {content?.title}
        </div>

        <div className='checkout__content_tabs'>
          {tabs?.map((item, index) => {
            return (
              <button
                className={`checkout__content_tabs_tab ${activeTab === index ? 'active' : ''}`}
                onClick={() => handleActiveTab(index)}
                key={index}
              >
                <div className={`checkout__content_tabs_tab_icon ${item?.icon}`} />
                {item?.title}
              </button>
            )
          })}
        </div>

        <div className={`checkout__content_caption ${activeTab === 0 ? 'one' : 'two'}`}>
          <div>
            {activeTab === 0 ? (
              content?.self_delivery_note
            ) : (
              content?.delivery_note
            )}
          </div>
        </div>

        <form
          className='checkout__content_fields'
          onSubmit={handleSubmit}
        >
          <div className='checkout__content_title'>
            {content?.contact_title}
          </div>

          <div className='checkout__content_fields_inputcontainer'>
            <label htmlFor="firstName">
              {content?.contact_name} <span>*</span>
            </label>

            <input
              name='firstName'
              value={values?.firstName}
              placeholder={content?.contact_name_placeholder}
              onChange={handleValue}
              required
            >
            </input>
          </div>

          <div className={`checkout__content_fields_inputcontainer ${errors?.phone ? 'error' : ''}`}>
            <label htmlFor='phone'>
              {content?.contact_phone} <span>*</span>
            </label>

            <PatternFormat
              className='order-input'
              format={'+38 (###) ### ## ##'}
              autoComplete="tel"
              type="text"
              allowEmptyFormatting
              mask='-'
              onValueChange={({ value }) => {
                handleValue({ target: { name: 'phone', value } })
                setError('phone', false)
              }}
              required
            />
          </div>

          <Swiper
            modules={[Navigation]}
            slidesPerView={1}
            onSwiper={((setSwiperRef) => swiper.current = setSwiperRef)}
            spaceBetween={20}
            autoHeight
            initialSlide={1}
          >
            <SwiperSlide>

            </SwiperSlide>
            <SwiperSlide style={activeTab === 0 ? { opacity: 0, pointerEvents: 'none' } : {}}>
              <div className={`checkout__content_fields_inputcontainer ${errors?.address ? 'error' : ''}`}>
                <label htmlFor="firstName">
                  {content?.contact_address} <span>*</span>
                </label>

                <input
                  id="address"
                  name='address'
                  value={values?.address}
                  placeholder={content?.contact_address_placeholder}
                  onChange={(e) => {
                    handleValue(e)
                    handleAddressInput(e)
                  }}
                  onFocus={(e) => {
                    setFocusedState(true)
                  }}
                  required={activeTab === 1}
                />

                {(focusedState && addressList?.length) ? (
                  <div
                    id="checkout__content_fields_inputcontainer_dropdown"
                    className='checkout__content_fields_inputcontainer_dropdown'
                  >
                    {addressList?.map((item, index) => {
                      return (
                        <button
                          id="checkout__content_fields_inputcontainer_dropdown_item"
                          className='checkout__content_fields_inputcontainer_dropdown_item'
                          onClick={() => {
                            handleValue({
                              target: {
                                name: 'address',
                                value: item?.structured_formatting?.main_text,
                              }
                            })
                            setFocusedState(false)
                          }}
                          key={index}
                        >
                          {item?.structured_formatting?.main_text}
                        </button>
                      )
                    })}
                  </div>
                ) : null}
              </div>

              {/* <div className="checkout__content_fields_inputcontainer">
                <label htmlFor='phone'>
                  {content?.contact_apartment}
                </label>

                <input
                  name='addressNumber'
                  type='number'
                  value={values?.addressNumber}
                  min="1"
                  placeholder={content?.contact_apartment}
                  onChange={handleValue}
                />
              </div> */}
            </SwiperSlide>
          </Swiper>

          <div className='checkout__content_fields_inputcontainer'>
            <label htmlFor='phone'>
              {content?.contact_comment}
            </label>

            <textarea
              name='comment'
              type='text'
              value={values?.comment}
              placeholder={activeTab === 0 ? content?.contact_comment_self_placeholder : content?.contact_comment_delivery_placeholder}
              onChange={handleValue}
            >
            </textarea>
          </div>

          <div className='checkout__content_caption'>
            {content?.delivery_time_note}
          </div>

          <div className='checkout__content_divider' />

          <div className='checkout__content_title'>
            {content?.total_title}
          </div>

          {activeTab === 1 && (
            <>
              <div className='checkout__content_caption'>
                {content?.total_free_delivery}
              </div>
              <div className='checkout__content_divider' />
            </>
          )}

          <div className='checkout__content_cart'>
            {items?.map((item, index) => {
              const selectedMark = selectedMarks?.find(item => item?.key === index)

              const arrayMarks = item?.marks ? Object.entries(item?.marks) : []

              const max = arrayMarks?.length > 1 ? 3 : 1
              const min = max === 1 || item?.marks?.[0] ? 0 : 1

              return (
                <div className='checkout__content_cart_item' key={index}>
                  <div>
                    <div className='checkout__content_cart_item_qty'>
                      x{item.quantity}
                    </div>

                    <div className='checkout__content_cart_item_title'>
                      {item.title} / {item.spec}
                    </div>

                    <div className='checkout__content_cart_item_price'>
                      {item.pricePerItem * item.quantity} грн
                    </div>

                    <button type="button" onClick={() => removeItem(item.id)} className='checkout__content_cart_item_delete'>
                      <Trash2 color='#CE3939' />
                    </button>
                  </div>

                  {item?.marks && (
                    <>
                      <Slider
                        className='checkout__content_cart_item_slider'
                        marks={item?.marks}
                        tooltip={{ formatter }}
                        min={min} max={max}
                        step={1}
                        defaultValue={Math.ceil(Object.values(item?.marks)?.length / 2)}
                        onChange={(value) => handleMarkValue(index)(value)}
                        trackStyle={{
                          backgroundColor: '#CF3839'
                        }}
                        handleStyle={{
                          boxShadow: '#CF3839'
                        }}
                        disabled={arrayMarks?.length === 1}
                      />
                      <div className='checkout__content_cart_item_slider-caption'>{item?.marks?.[selectedMark?.value]}</div>
                    </>
                  )}
                </div>
              )
            })}
          </div>

          <div className='checkout__content_caption'>
            {content?.total_amount_title} <span>{totalCartPrice} {content?.unit}</span>
          </div>

          <div className='checkout__content_divider' />

          <div className='checkout__content_caption'>
            {content?.delivery_contact_way}
          </div>

          <button
            className={`checkout__content_submit ${activeTab === 0 ? 'blue' : 'red'}`}
            type="submit"
            disabled={loading || errors?.phone || errors?.firstName || errors?.address}
          >
            {content?.confirm_title}
          </button>
        </form>
      </div>
    </div>
  )
}

export default Order
