import React, { useState, useEffect, } from 'react'
import ReactGA from 'react-ga';
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
import './styles.css'

import NewMenu from "../NewMenu";

function MainPage() {
  const location = useLocation();
  const navigate = useNavigate()

  const view = location?.state?.view

  const [list, setList] = useState(null)
  const [isDisabled, setDisabled] = useState(false)

  const setInitialList = (disabled) => {
    setList([
      {
        title: 'Меню (Українська)',
        suffix: 'ukr',
        onClick: () => {
          navigate('/', { state: { view: 'order', lang: 'ukr' } })
        },
        disabled,
      },
      {
        title: 'Menu (English)',
        suffix: 'gb',
        onClick: () => {
          navigate('/', { state: { view: 'order', lang: 'eng' } })
        },
        disabled,
      },
      {},
      {
        title: 'Доставка та самовивіз',
        caption: 'замовляйте страви одразу на сайті',
        // caption: 'ми зачинені до 5.07',
        suffix: 'delivery',
        onClick: () => navigate('/', { state: { view: 'order', lang: 'ukr' } }),
        disabled,
      },
      {
        title: '+380 97 118 1852',
        caption: 'подзвонити нам',
        suffix: 'phone',
        onClick: () => window.open("tel:+380 97 118 1852", '_self'),
        disabled,
      },
      {
        title: 'вул. Грушевського 70',
        caption: 'відкрити в Google Maps',
        suffix: 'route',
        onClick: () => window.open('https://www.google.com/maps/place/%D0%A5%D0%BE+%D0%A7%D1%83+%D0%91%D0%BE+%D0%B0%D0%B7%D1%96%D0%B9%D1%81%D1%8C%D0%BA%D0%B5+%D0%B1%D1%96%D1%81%D1%82%D1%80%D0%BE/@49.2359911,28.4614838,17z/data=!3m1!4b1!4m6!3m5!1s0x472d5b6204cb7d79:0x775cd4c20763dd2!8m2!3d49.2359911!4d28.4636725!16s%2Fg%2F11h5lz7bk2?coh=164777&entry=tt')
      },
      {
        title: 'Вакансії',
        caption: 'Vacancies',
        suffix: 'jobs',
        onClick: () => navigate('/vacancies')
      },
      {
        title: 'Instagram',
        suffix: 'inst',
        onClick: () => window.location = "https://instagram.com/hochubo"
      },
    ])
  }

  const getOptions = async () => {
    try {
      const apiURL = process.env.NODE_ENV === 'development' ? 'http://10.15.16.126:4202/v1/options' : 'https://hochubo-api.herokuapp.com/v1/options'

      const data = await fetch(apiURL)

      const options = await data.json()

      const isDisabled = !options?.enabled

      setInitialList(isDisabled)
      setDisabled(isDisabled)
    } catch (error) {
      console.log('error', error)

      setInitialList(false)
    }
  }

  useEffect(() => getOptions(), [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    ReactGA.pageview(window?.location?.pathname + window?.location?.search);
  }, [])

  const handleClick = (action) => {
    if (!action?.disabled) {
      if (action?.onClick) {
        action.onClick()
      }
    }
  }



  return (
    <div style={{ overflow: view !== 'order' ? 'hidden' : 'unset', position: 'relative' }}>
      {isDisabled && (
        <>
          <div className='banner'>
            <div>
              <h3>
                Сьогодні ми зачинені. Слідкуйте за оновленнями в нашому Instagram.
              </h3>
            </div>
          </div>
          {/* <div className='banner regular'>
            <div>
              <h3>
                Ми зачинені 12, 13 та 14 серпня. Вибачте за незручності

                <br /> З 15 серпня працюємо за звичним режимом з 11:00 до 21:00
              </h3>
            </div>
          </div> */}
        </>
      )}
      <div div className='main-page' style={{ opacity: view === 'order' ? 0 : 1, pointerEvents: view === 'order' ? 'none' : 'all' }
      }>
        <div className='customer-logo' />

        <div className='customer-title'>
          Ho Chu Bo
        </div>

        <div className='customer-caption'>
          азійське бістро
        </div>

        <div className='customer-openhours'>
          пн-нд 11:00 - 21:00
        </div>

        <div className='customer-actions-list'>
          {list?.map((action, index) => {
            if (!action?.title) {
              return <div className='c-action-divider' key={index} />
            }

            return (
              <div
                className={`c-action ${action?.disabled ? 'disabled' : ''}`}
                onClick={() => handleClick(action)}
                key={index}
              >
                <div className='c-action-left'>
                  <div className={`c-action-flat-icon ${action?.suffix}`}>
                    {action?.suffix === 'vac' && '0'}
                    {action?.suffix === 'feedback' && '!'}
                  </div>

                  <div className='c-action-title'>
                    {action?.title}

                    {action?.caption && (
                      <span>
                        {action?.caption}
                      </span>
                    )}
                  </div>
                </div>

                <div className='c-action-arrow-icon' />
              </div>
            )
          })}
        </div>
      </div >

      <div className='gradient' style={{ position: 'absolute', top: 0, opacity: view === 'order' ? 1 : 0, pointerEvents: view === 'order' ? 'all' : 'none' }}>
        <NewMenu deliveryMode />
      </div>
    </div >
  )
}

export default MainPage
