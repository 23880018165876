import React, { useState, useEffect, useMemo, Fragment } from 'react'
import ReactGA from 'react-ga';
import { useNavigate, useLocation } from 'react-router-dom'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link, Element, scroller } from 'react-scroll'
import useSmoothHorizontalScroll from "use-smooth-horizontal-scroll";
import { useCart } from "react-use-cart";
import _ from 'lodash'
import { Trash2 } from 'lucide-react';
import './styles.css'

import ukr_data from './ukr_data.json'
import eng_data from './eng_data.json'

function NewMenu(props) {
  const { deliveryMode } = props

  const location = useLocation();
  const navigate = useNavigate()

  const { scrollContainerRef, scrollTo } = useSmoothHorizontalScroll();

  const { items, addItem, updateItemQuantity, emptyCart } = useCart()
  
  console.log('items', items)

  const isUkr = location?.state?.lang === 'ukr'

  const totalCartPrice = useMemo(() => {
    const totals = items?.map(item => item.quantity * item.pricePerItem)
    const totalPrice = totals.reduce((acc, curr) => curr + acc, 0)

    return totalPrice
  }, [items])

  const [list, setList] = useState(null)
  const [agenda, setAgenda] = useState(null)
  const [scrollTopVisible, setScrollTopVisible] = useState(false)
  const [quicklinksFixed, setQuicklinksFixed] = useState(false)
  const [featuresOpened, setFeaturesOpened] = useState(false)
  const [cartTextContents, setCartTextContents] = useState(null)

  const closeFeaturesAgenda = () => {
    setFeaturesOpened(false)
  }

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setScrollTopVisible(window.scrollY > 200)
    })
  }, [])

  useEffect(() => {
    const handleScroll = _.debounce(() => {
      const activeAnchor = scroller.getActiveLink()

      const anchorElem = document.querySelector(`a[name='link-${activeAnchor}']`)

      if (anchorElem) {
        const rect = anchorElem.getBoundingClientRect();

        const screenSize = window.screen.width
        const listWidth = document.getElementById('quick-links').clientWidth;
        const anchorWidth = rect.width

        const screenMiddle = screenSize / 2
        const anchorPosition = rect.left

        if (anchorElem?.offsetLeft) {
          const scrollOffset = screenMiddle - (listWidth - anchorPosition)

          scrollTo(scrollOffset + (anchorWidth / 2))
        }

      }
    }, 200)

    window.addEventListener("scroll", handleScroll);
  }, [])

  useEffect(() => {
    getMenuList()
  }, [isUkr])

  useEffect(() => {
    ReactGA.pageview(window?.location?.pathname + window?.location?.search);
  }, [])

  const getMenuList = async () => {
    try {
      if (isUkr) {
        setCartTextContents(ukr_data?.cart)
        setAgenda(ukr_data?.agenda)
        setList(ukr_data?.list)
      } else {
        setCartTextContents(eng_data?.cart)
        setAgenda(eng_data?.agenda)
        setList(eng_data?.list)
      }
    } catch (error) {
      console.log('ERROR - getMenuList', error.message)
    }
  }

  const handleGoBack = () => {
    navigate('/', { state: null })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    document.addEventListener('scroll', () => {
      setQuicklinksFixed(window.scrollY > 70)
    })
  }, [])

  return (
    <div className='new-menu' id='new-menu'>
      {featuresOpened && (
        <div className='agenda-wrapper' onClick={closeFeaturesAgenda}>
          <div className='agenda-container'>
            {agenda?.map((item, index) => (
              <div className='agenda-row' key={index}>
                <div className={`ml-item-icon ${item?.icon} agenda`} />

                <div className='agenda-row-desc'>
                  {item?.description}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='page-head'>
        <div className='page-nav' onClick={handleGoBack}>
          <div className='page-icon back' />
          <div className='page-title'>
            {isUkr ? 'Назад' : 'Back'}
          </div>
        </div>

        <div className='page-icon' />
      </div>

      <div className={`quick-links-wrapper new ${quicklinksFixed ? 'fixed' : ''}`}>
        <div ref={scrollContainerRef} id="quick-links" className='quick-links'>
          {list?.map((link, index) => (
            <Fragment key={index}>
              <Link
                className="link"
                activeClass="active"
                name={`link-${link?.anchor}`}
                to={link?.anchor}
                spy={true}
                smooth={true}
                offset={-80} duration={500}
              >
                {link?.title}
              </Link>
            </Fragment>
          ))}
        </div>
      </div>

      <div className='newmenu-list'>
        <div className="embla">
          <div className="embla__container">
            {list?.map((chunk, key) => (
              <Element name={chunk?.anchor} className='embla__slide' key={key}>
                <div className='newmenu-chunk-anchor' />
                <div className='newmenu-chunk-title'>
                  {chunk?.title}
                </div>

                <div className="newmenu-chunk">
                  {chunk?.list?.map((menuItem, index) => (
                    <div
                      className="newmenu-chunk-item"
                      key={index}
                    >
                      {menuItem?.image_key ? (
                        <img
                          className='newmenu-chi-image'
                          width="38vw"
                          height="38vw"
                          src={menuItem?.image_key ? menuItem?.image_key : "/assets/images/menu/q.png"}
                          alt={menuItem?.title + ' image'}
                          loading="lazy"
                        />
                      ) : <div className='newmenu-chi-image' />}

                      <div className='newmenu-chi-title'>
                        {menuItem?.title}
                      </div>

                      <div className='newmenu-chi-desc'>
                        {menuItem?.description}
                      </div>

                      {menuItem?.features?.length ? (
                        <div className='ml-items'>
                          {menuItem?.features?.map((item, key) => (
                            <div className={`ml-item-icon ${item}`} key={key} />
                          ))}
                        </div>
                      ) : null}

                      <div className='newmenu-chi-details'>
                        {menuItem?.price?.map((pr, key) => {
                          const quantity = items?.find(item => item.id === pr.id)?.quantity

                          return (
                            <div className='newmenu-chi-detailsrow' key={key}>
                              <div className='newmenu-chi-dr-content'>
                                <div className='newmenu-chi-dr-left'>
                                  {pr?.title}
                                </div>

                                <div className='newmenu-chi-dr-right'>
                                  {pr?.price + ' UAH'}
                                </div>
                              </div>

                              {deliveryMode && !menuItem?.exclude_from_delivery && (
                                <div className='newmenu-chi-dr-actions'>
                                  {!quantity ? (
                                    <button
                                      className='newmenu-chi-dr-add'
                                      onClick={() => addItem({ ...menuItem, pricePerItem: pr.price, spec: pr.title, id: pr.id })}
                                    >
                                      +
                                    </button>
                                  ) : (
                                    <Fragment>
                                      <button
                                        className='newmenu-chi-dr-add'
                                        onClick={() => updateItemQuantity(pr.id, quantity - 1)}
                                      >
                                        -
                                      </button>

                                      <div className='newmenu-chi-dr-qty'>
                                        {quantity}
                                      </div>

                                      <button
                                        className='newmenu-chi-dr-add'
                                        onClick={() => addItem({ ...menuItem, pricePerItem: pr.price, id: pr.id })}
                                      >
                                        +
                                      </button>
                                    </Fragment>
                                  )}
                                </div>
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </Element>
            ))}
          </div>
        </div>
      </div>

      {deliveryMode ? (
        totalCartPrice ? (
          <button className='order-cta' onClick={() => navigate(`/checkout`, { state: { lang: location?.state?.lang } })}>
            <div className={`c-action-flat-icon delivery`} />

            <div className='order-cta-content'>
              <div className='order-cta-content-title'>
                {cartTextContents?.title}
              </div>

              <div className='order-cta-content-description'>
                {cartTextContents?.totalTitle} {totalCartPrice} {cartTextContents?.unit}
              </div>
            </div>

            <button className='c-action-trash-icon' onClick={(e) => {
              e.stopPropagation()
              emptyCart();
            }}>
              <Trash2 strokeWidth="1.5" width="28" height="28" color='#CE3939' />
            </button>
          </button>
        ) : null
      ) : (
        scrollTopVisible && (
          <AnchorLink
            className='scroll-top'
            href='#new-menu'
          />
        )
      )
      }
    </div >
  )
}

export default NewMenu
